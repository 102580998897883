import React from "react";
import theme from "theme";
import { Theme, Text, Image, Link, LinkBox, Box, Section, List, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"works/quarkly"} />
		<Helmet>
			<title>
				Кейс Quarkly | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"Quarkly — это no-code / low-code платформа для создания сайтов и небольших веб-приложений."} />
			<meta property={"og:title"} content={"Кейс Quarkly | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"Quarkly — это no-code / low-code платформа для создания сайтов и небольших веб-приложений."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header>
				<Override slot="link" />
				<Override slot="link1" />
				<Override slot="link2" />
			</Components.Header>
			<Section quarkly-title="Cover" margin="50px 0 0px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="center"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					grid-column="2/3"
					display="flex"
					flex-direction="row"
					flex-wrap="wrap"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--headline2"
						width="100%"
						sm-font="--headline3"
					>
						Quarkly
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						Quarkly — это no-code / low-code платформа для создания сайтов и небольших веб-приложений.
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						В Quarkly дизайнер может работать над проектом как один, так и совместно с фронтенд разработчиком. Для дизайнера есть широкие возможности оформления. А для разработчика возможность создавать React.js компоненты для дизайнера.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="66%"
						padding="0px 16px 0px 0px"
						sm-width="100%"
						sm-height="auto"
						sm-min-height="auto"
						sm-margin="0px 0px 18px 0px"
					>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://test-upl.quarkly.io/5e60efa12db4d10024432a9f/images/favicon-152w.png?v=2020-09-25T11:53:45.538Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
								srcSet="https://smartuploads-beta.quarkly.io/5e60efa12db4d10024432a9f/images/favicon-152w.png?v=2020-09-25T11%3A53%3A45.538Z&quality=85&w=500 500w,https://smartuploads-beta.quarkly.io/5e60efa12db4d10024432a9f/images/favicon-152w.png?v=2020-09-25T11%3A53%3A45.538Z&quality=85&w=800 800w,https://smartuploads-beta.quarkly.io/5e60efa12db4d10024432a9f/images/favicon-152w.png?v=2020-09-25T11%3A53%3A45.538Z&quality=85&w=1080 1080w,https://smartuploads-beta.quarkly.io/5e60efa12db4d10024432a9f/images/favicon-152w.png?v=2020-09-25T11%3A53%3A45.538Z&quality=85&w=1600 1600w,https://smartuploads-beta.quarkly.io/5e60efa12db4d10024432a9f/images/favicon-152w.png?v=2020-09-25T11%3A53%3A45.538Z&quality=85&w=2000 2000w,https://smartuploads-beta.quarkly.io/5e60efa12db4d10024432a9f/images/favicon-152w.png?v=2020-09-25T11%3A53%3A45.538Z&quality=85&w=2600 2600w,https://smartuploads-beta.quarkly.io/5e60efa12db4d10024432a9f/images/favicon-152w.png?v=2020-09-25T11%3A53%3A45.538Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,(max-width: 1536px) 100vw,100vw"
							/>
							<Link
								href="https://quarkly.io/"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								quarkly.io
							</Link>
						</LinkBox>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="33%"
						sm-width="100%"
						sm-height="auto"
						sm-min-height="auto"
					>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							UX / UI дизайн
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Продукт менеджмент
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Продукт дизайн
						</Text>
					</Box>
				</Box>
			</Section>
			<Section quarkly-title="YoutubeVideo" margin="0 0 12px 0" inner-width="100%">
				<Components.QuarklycommunityKitYouTube
					url="https://youtu.be/K7bAu9BhXh8"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделать конструктор сайтов с широкими возможностями оформления, с широкими функциональными возможностями, простым в пользовании и на современных технологиях.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" sm-margin="0px 24px 16px 0px">
					МОЯ РОЛЬ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сооснователь продукта. Я был ответственным за всю визуальную и продуктовую составляющую.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Первичный опрос
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Понять кому такая идея может быть интересна.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделал промо-страницу, где было описана идея и приглашение подписаться на уведомление о бета версии. На этой странице был небольшой опрос.
				</Text>
			</Section>
			<Section quarkly-title="Example" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Override slot="SectionContent" display="flex" grid-template-columns="1fr 640px 1fr" grid-gap="16px" />
				<Components.QuarklyUpcomingSurveyResults />
				<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2" max-width="720px">
					Результат анкеты-опроса
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					В результате понял, что интересно не только разработчикам и дизайнерам, но и продакт менеджерам, маркетологам, предпринимателям
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Опрос бета-пользователей
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Собрать отзывы на первую версию конструктора. Понять какие фичи нужны в первую очередь влияют на удовлетворенность пользователя.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я сделал два опроса: для русскоязычной и англоязычной аудиторий. Респонденты были из числа тех, кто пользовался конструктором некоторое время.
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сам опрос состоял из нескольких блоков:
				</Text>
				<List
					margin="16px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						Небольшая анкета для понимания бэкграунда респондента;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Блок про впечатления. Поможет в дальнейшей активации пользователей;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Что мог бы заменить Quarkly? Поможет в поиске новых пользователей среди инструментов-конкурентов;
					</Text>
					<Text margin="0px 0px 0px 0px">
						NPS оценка. Простой способ понять удовлетворенность;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Обоснование поставленной оценки. Что нужно будет исправить;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Характеристика полученного в процессе работы результата, удобства работы и скорости;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Что помогло в процессе работы, какие фичи были использованы, а какие остались невостребованными;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Голосование за будущие фичи. Я решил использовать анализ Кано для понимания как разные группы пользователей смотрят на фичи.
					</Text>
				</List>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline3"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Дизайнеры
				</Text>
			</Section>
			<Section quarkly-title="Example" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Override slot="SectionContent" display="flex" grid-template-columns="1fr 640px 1fr" grid-gap="16px" />
				<Components.QuarklyKanoDesigners />
				<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2" max-width="720px">
					Анализ Кано: какие фичи важны для дизайнеров
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					font="--lead28"
					color="--darkL2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
				>
					NPS группы дизайнеров:{" "}
					<Strong
						color="tomato"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						-15.38%
					</Strong>
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					font="--lead21"
					color="--darkL2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
				>
					Это довольно низкий процент. Нужно присмотреться к нуждам дизайнеров. Почему они ставили низкую оценку:
				</Text>
				<List
					margin="16px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						Сделайте компоненты и доработать холст с превью сайта;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Сделать инструмент с максимально низким порогом вхождения;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Прислушиваться к пользовательскому опыту тестировщиков;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Добавить больше различных блоков;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Сделать удобнее и понятнее интерфейс, исправить мелкие баги;
					</Text>
					<Text margin="0px 0px 0px 0px">
						можно улучшить ui/ux составляющую;
					</Text>
					<Text margin="0px 0px 0px 0px">
						make the user experience more easy;
					</Text>
					<Text margin="0px 0px 0px 0px">
						add more blocks;
					</Text>
					<Text margin="0px 0px 0px 0px">
						More components that are commonly used can be added and integrations can be done.
					</Text>
				</List>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					font="--lead28"
					color="--darkL2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
				>
					Идеи для посадочных страниц и маркетинговых материалов
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					font="--lead21"
					color="--darkL2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
				>
					Из вопросов «Что было самой впечатляющей частью в процессе знакомства с Quarkly?» и «Что вас удивило (положительным или отрицательным образом) в процессе знакомства с Quarkly?»:
				</Text>
				<List
					margin="16px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						Широкий круг применения;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Использование и интегрирование дизайн-систем;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Скорость работы;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Количество тонких настроек;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Что изменения можно вносить прямо в код;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Можно верстать самой и в меньшей степень задействовать фронтенда;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Похож на Figma;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Интерфейс очень похож на Figma;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Автоматическая адаптивность;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Кастомные реакт компоненты;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Возможность создавать компоненты с холста;{" "}
					</Text>
					<Text margin="0px 0px 0px 0px">
						Кастомные брейкпоинты;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Широкие возможности стилизации;
					</Text>
					<Text margin="0px 0px 0px 0px">
						It gives the coding experience like you coding without code;
					</Text>
					<Text margin="0px 0px 0px 0px">
						It saved my time for making landing pages.
					</Text>
				</List>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					font="--lead28"
					color="--darkL2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
				>
					Пользователям чего можно предлагать Quarkly
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					font="--lead21"
					color="--darkL2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
				>
					Из вопроса «Что вам мог бы заменить Quarkly в повседневной работе?»:
				</Text>
				<List
					margin="16px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						Readymag;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Инструмент верстки сайта;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Верстку индивидуальных проектов;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Фронтенда;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Верстальщика;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Верстку сайта;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Тильду, да и в принципе все конструкторы сайтов;
					</Text>
					<Text margin="0px 0px 0px 0px">
						ручную верстку сайтов;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Wix;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Bootstrap Studio;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Jamstack.
					</Text>
				</List>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline3"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Разработчики
				</Text>
			</Section>
			<Section quarkly-title="Example" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Override slot="SectionContent" display="flex" grid-template-columns="1fr 640px 1fr" grid-gap="16px" />
				<Components.QuarklyKanoDevelopers />
				<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2" max-width="720px">
					Анализ Кано: какие фичи важны для разработчиков
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="0px 0px 24px 0px"
					font="--lead28"
					color="--darkL2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
				>
					NPS группы разработчиков:{" "}
					<Strong
						color="forestgreen"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						23.53%
					</Strong>
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline3"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Предприниматели
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="0px 0px 24px 0px"
					font="--lead28"
					color="--darkL2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
				>
					NPS группы предпринимателей:{" "}
					<Strong
						color="forestgreen"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						50.00%
					</Strong>
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Экспертная оценка
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделать очное тестирование продукта экспертами. Узнать их мнение и понять с какими ошибками сталкиваются пользователи при первом знакомстве.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я попросил нескольких экспертов собрать небольшую страницу в Quarkly. Далее я наблюдал какие препятствия у них возникают в процессе работы.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Всего было проведено 6 сессий:
				</Text>
				<List
					margin="16px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						Продукт дизайнер,
					</Text>
					<Text margin="0px 0px 0px 0px">
						Веб-дизайнер эксперт Webflow,
					</Text>
					<Text margin="0px 0px 0px 0px">
						Продукт менеджер,
					</Text>
					<Text margin="0px 0px 0px 0px">
						Маркетолог,
					</Text>
					<Text margin="0px 0px 0px 0px">
						Управляющий студией веб-дизайна,
					</Text>
					<Text margin="0px 0px 0px 0px">
						Предприниматель.
					</Text>
				</List>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					По ходу тестирования я фиксировал все значимые моменты. В последствие это превратилось в список задач.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Помимо задач, открылись новые интересные идеи. Например, от маркетолога, что Quarkly хорошо подходит для быстрого создания большого количества посадочных страниц.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-expert-testing-product-designer-ru-1.png?v=2022-06-27T06:37:15.085Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Результаты тестирования продукт дизайнера
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-expert-testing-webflow-designer-ru-2.png?v=2022-06-27T06:38:03.318Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Результаты тестирования веб-дизайнера эксперта Webflow
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-expert-testing-product-manager-ru-3.png?v=2022-06-27T06:39:29.541Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Результаты тестирования продукт менеджера
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-expert-testing-marketer-ru-4.png?v=2022-06-27T06:39:37.998Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Результаты тестирования маркетолога
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-expert-testing-head-of-web-studio-ru-5.png?v=2022-06-27T06:40:40.993Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Результаты тестирования управляющего студией веб-дизайна
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Конкурентный анализ
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Сделать анализ конкурентов: какие фичи у них есть, что используют для формирования комьюнити, размер пользовательской базы, прайсинг, что используют для маркетинга, какой онбординг, письма, поддержка и т.д.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Изучил и собирал всё в Airtable для удобного дальнейшего анализа.
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Отдельно сделал таблицу с минимальным набором компонентов в популярных конструкторах. Это помогло сформировать свой список примитивов и компонентов для каталога.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-research-competitors-en-1.png?v=2022-06-26T20:14:20.015Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Таблица конкурентов
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-research-primitives-en-2.png?v=2022-06-26T20:14:20.022Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Таблица с примитивами, которые есть у конкурентов
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Соревнование «вёрстка battle»
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Опробовать конструктор в экстремальных условиях, показать возможности, познакомить аудиторию с конструктором.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Суть соревнования в сборке одной страницы на время. Кто быстрее и качественнее сделает — тот победил.
				</Text>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Для проведения соревнования я подготовил макет, правила, и критерии оценки результата. При поддержке коллег мы собрали участников, среди которых были как верстальщики, пользователи конструкторов Tilda, Webflow, Wordpress, uKit, и других.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Результат превзошел ожидания. Что получилось в итоге:
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						Отловили некоторые баги;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Увидили недостатки при работе в сжатых сроках;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Посмотрели как профессиональные пользователи конкурентов;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Навсегда убедились что конструкторы быстрее и качественнее вёрстки руками;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Привлекли внимание к инструменту, повысились регистрации;
					</Text>
					<Text margin="0px 0px 0px 0px">
						Убедились в том, что Quarkly может уверенно конкурировать с существующими конструкторами.
					</Text>
				</List>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Соревнование приобрело некоторую популярность. В итоге мы провели 4 батла.
				</Text>
			</Section>
			<Section quarkly-title="YoutubeVideo" margin="0 0 12px 0" inner-width="100%">
				<Components.QuarklycommunityKitYouTube
					url="https://www.youtube.com/watch?v=kI2mgE6egyE"
					width="100%"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
				/>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Юзабилити тесты
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Необходимо провести юзабилити тестирование лендинга, регистрации, дашборда и выбор шаблона. Убедиться что там всё хорошо, в противном случае выписать все недоработки.
Респонденты должны быть дизайнерами.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					С коллегой мы нашли нужных респондентов и провели тестирование по методике 5 вопросов:
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						Что видишь?
					</Text>
					<Text margin="0px 0px 0px 0px">
						Какие мысли тебе приходят в голову? / Что думаешь?
					</Text>
					<Text margin="0px 0px 0px 0px">
						Что чувствуешь?
					</Text>
					<Text margin="0px 0px 0px 0px">
						Что можешь сделать?
					</Text>
					<Text margin="0px 0px 0px 0px">
						Какое действие ты хочешь сделать? / Что хочешь сделать?
					</Text>
				</List>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Важным моментом было задавать эти вопросы на каждом экране.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Всего провели 6 удаленных тестирований. После тестов я сформировал результаты в виде задач и сгруппировал по разделам.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-usability-test-ivan-ru-2.png?v=2022-06-26T20:49:45.618Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Результаты юзабилити тестирования респондента
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-usability-test-dmitri-ru-1.png?v=2022-06-26T20:49:45.616Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Результаты юзабилити тестирования респондента
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Product/Market fit опрос
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Опросить пользователей по методике{" "}
					<Link
						href="http://seanellis.me/"
						target="_blank"
						color="--primary"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Шона Эллиса
					</Link>
					. Понять текущее продуктово-рыночное соответствие.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Составил опросник и разослал его по пользовательской базе пользователей. Добавил также на главную страницу приглашение пройти опрос за скидку на платный тариф.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Опрос на тот момент прошло около 50 человек. На главный вопрос «How would you feel if you could no longer use Quarkly?» — «Very disappointed» ответили только 25%. Для хорошего продуктово-рыночного соответствия должно быть более 40% по эвристике Шона Эллиса.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Другая полезная информация в том, кто же эти 25% что ответили «Very dissapointed»:
				</Text>
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					<Text margin="0px 0px 0px 0px">
						2 Entrepreneurs
					</Text>
					<Text margin="0px 0px 0px 0px">
						3 Developers
					</Text>
					<Text margin="0px 0px 0px 0px">
						2 Designers
					</Text>
					<Text margin="0px 0px 0px 0px">
						Manager
					</Text>
					<Text margin="0px 0px 0px 0px">
						Student
					</Text>
					<Text margin="0px 0px 0px 0px">
						Developer-Enthusiast
					</Text>
					<Text margin="0px 0px 0px 0px">
						Designer / Developer / Entrepreneur
					</Text>
					<Text margin="0px 0px 0px 0px">
						Manager / Developer / Entrepreneur
					</Text>
					<Text margin="0px 0px 0px 0px">
						Designer / Developer
					</Text>
				</List>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(4, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="2/4"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-pmf-survey-ru-1.png?v=2022-06-26T21:26:39.878Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Опрос на продуктово-рыночное соответствие
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Результаты
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					В Quarkly около 16500 регистраций. В конце 2020 году это был продукт дня на ProductHunt.
				</Text>
			</Section>
			<Components.PrevNextButtons>
				<Override slot="linkBox" href="/works/stepform" />
				<Override slot="linkBox1" href="/works/mini-projects" />
			</Components.PrevNextButtons>
			<Components.Footer>
				<Override slot="SectionContent" />
			</Components.Footer>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(31525948, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
		</RawHtml>
	</Theme>;
});